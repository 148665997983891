@import url('https://fonts.googleapis.com/css2?family=Nova+Slim&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nova+Slim&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply bg-gradient-to-r from-zinc-900 to-gray-800;
    }
  
  }

  .active {

    border-bottom: 2px solid gray;
  
    padding-bottom: 0.40em;
  
  }


.icon {
    color: #166534;
    transition: color 0.2s ease-in;
  }
.icon:hover {
   color: #FFFFFF;
   transform: scale(1.8);
   transition: all 0.6s ease-in;

}

